/* eslint-disable @typescript-eslint/no-explicit-any */

import { clsx } from "@frend-digital/ui";
import {
	Children,
	type ComponentProps,
	type DetailedReactHTMLElement,
	cloneElement,
} from "react";

type SlotProps = ComponentProps<"div" | "button" | "a">;

export function Slot<
	P extends { className?: string; style?: React.CSSProperties } = SlotProps,
>({ children, ...props }: SlotProps) {
	let SingleChild = null as DetailedReactHTMLElement<P, any> | null;

	try {
		SingleChild = Children.only(children) as DetailedReactHTMLElement<P, any>;
	} catch (error) {
		if (process.env.NODE_ENV === "development") {
			console.error("Slot component must have exactly one child, got", children);
			throw error;
		}
	}

	if (!SingleChild) {
		console.warn("Slot component must have exactly one child");
		return null;
	}

	return cloneElement(SingleChild, {
		...props,
		className: clsx(SingleChild.props?.className, props?.className),
		style: { ...SingleChild.props?.style, ...props?.style },
	});
}
