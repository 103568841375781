"use client";

import { Close24xLarge } from "@/components/icons";
import { Button } from "@/components/ui/button";
import {
	ModalClose,
	ModalContent,
	ModalHeader,
	ModalRoot,
	ModalViewport,
} from "@/components/ui/modal";
import { LocationInputs, useLocationAction } from "@frend-digital/centra/location";
import { Slot } from "@frend-digital/ui";
import { useWindowResize } from "@frend-digital/ui/hooks";
import {
	startTransition,
	Suspense,
	useCallback,
	useRef,
	type ComponentProps,
	type ReactNode,
} from "react";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { LocationContext } from "./context";
import styles from "./index.module.css";
export type CountrySelectorModalProps = Partial<{
	children: ReactNode;
	country?: string;
}>;

interface CountryModalState {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const useCountryModal = create(
	persist<CountryModalState>(
		(set) => ({
			open: false,
			setOpen: (open) => set({ open }),
		}),
		{
			name: "country-modal",
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);

const UnsuspendedCountrySelectorModal = ({
	children,
	country,
}: CountrySelectorModalProps) => {
	const { open, setOpen } = useCountryModal();
	const ref = useRef<HTMLFormElement>(null!);

	const [state, formAction, pending] = useLocationAction();

	const trigger = useCallback(() => {
		startTransition(() => {
			const { current } = ref;
			const formData = new FormData(current);

			formData.set(LocationInputs.country, country!);

			formAction(formData);
		});
	}, [formAction, country]);

	useWindowResize(() => {
		setOpen(false);
	});

	return (
		<LocationContext.Provider
			value={{ state, action: formAction, pending, trigger: trigger }}>
			<ModalRoot open={open} onOpenChange={setOpen}>
				<ModalViewport>
					<ModalContent className={styles.modalContent}>
						<ModalHeader className={styles.modalPadding}>
							<div></div>
							<ModalClose asChild type="button" onClick={() => setOpen(false)}>
								<Button variant="unstyled">
									<Close24xLarge />
								</Button>
							</ModalClose>
						</ModalHeader>
						<form onChange={trigger} ref={ref} className={styles.form}>
							{children}
						</form>
					</ModalContent>
				</ModalViewport>
			</ModalRoot>
		</LocationContext.Provider>
	);
};

export const CountrySelectorModal: typeof UnsuspendedCountrySelectorModal = (props) => {
	return (
		<Suspense>
			<UnsuspendedCountrySelectorModal {...props} />
		</Suspense>
	);
};

const UnsuspendedCountrySelectorTrigger = ({
	asChild,
	children,
	...rest
}: ComponentProps<"button"> & {
	asChild?: boolean;
}) => {
	const { setOpen } = useCountryModal();

	const Component = asChild ? Slot : "button";

	return (
		<Component
			onClick={() => {
				setOpen(true);
			}}
			{...rest}>
			{children}
		</Component>
	);
};

export const CountrySelectorTrigger: typeof UnsuspendedCountrySelectorTrigger = (
	props,
) => {
	return (
		<Suspense>
			<UnsuspendedCountrySelectorTrigger {...props} />
		</Suspense>
	);
};
