"use client";

import { LocationInputs, useLocationAction } from "@frend-digital/centra/location";
import { clsx } from "@frend-digital/ui";
import { useLocale } from "next-intl";
import Image from "next/image";
import { startTransition, useCallback } from "react";
import { Button } from "../button";
import styles from "./index.module.css";

type FlagButtonProps = {
	className?: string;
	text?: string;
	countryCode?: string;
	selected?: boolean;
};

export const FlagButton = ({
	text,
	countryCode,
	selected,
	className,
}: FlagButtonProps) => {
	const language = useLocale();
	const [_, formAction] = useLocationAction();

	const trigger = useCallback(() => {
		startTransition(() => {
			const formData = new FormData();

			formData.append(LocationInputs.country, countryCode!);
			formData.append(LocationInputs.language, language);

			formAction(formData);
		});
	}, [formAction, countryCode, language]);

	return (
		<Button
			className={clsx(styles.root, selected && styles.selected, className)}
			onClick={(e) => {
				e.preventDefault();

				trigger();
			}}>
			<div className={styles.main}>
				{countryCode && (
					<div className={styles.flagImage}>
						<Image
							src={`https://flagcdn.com/w80/${countryCode.toLowerCase()}.png`}
							alt=""
							width={64}
							height={64}
						/>
					</div>
				)}
				<span className={clsx(styles.text)}>{text}</span>
			</div>
		</Button>
	);
};
