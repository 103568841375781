"use client";

import { useUrlSearchParams } from "@frend-digital/ui/next";
import { Suspense, useMemo } from "react";

import type { FilterParams } from "@/components/bloks/RetailersBlok";
import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "next/navigation";
import { Input } from "../Input";
import { Select } from "../Select";
import styles from "./index.module.css";

export const RETAILER_FILTER_COUNTRY_PARAM = "country";
export const RETAILER_FILTER_CITY_PARAM = "city";

export const RETAILER_FILTER_Q_PARAM = "q";

export const RetailerFilterBar: typeof UnsuspendedRetailerFilterBar = (props) => {
	return (
		<Suspense>
			<UnsuspendedRetailerFilterBar {...props} />
		</Suspense>
	);
};

export const UnsuspendedRetailerFilterBar = ({
	locations,
}: {
	locations: FilterParams[];
}) => {
	const t = useTranslations("retailer");
	const pathname = usePathname();
	const router = useRouter();

	const searchParams = useUrlSearchParams({
		shallow: false,
	});

	const shallowParams = useUrlSearchParams();

	const selectedCountry = searchParams.get(RETAILER_FILTER_COUNTRY_PARAM);
	const selectedCity = searchParams.get(RETAILER_FILTER_CITY_PARAM);
	const q = shallowParams.get(RETAILER_FILTER_Q_PARAM);

	const { uniqueCities, uniqueCountries } = useMemo(() => {
		const uniqueCountries = new Set(locations.map((l) => l.country).filter(Boolean));
		const uniqueCities = new Set(
			locations
				.filter((l) => (selectedCountry ? l.country === selectedCountry : true))
				.map((l) => l.city)
				.filter(Boolean),
		);

		return {
			uniqueCities: Array.from(uniqueCities).map((c) => ({
				name: c,
				value: c,
			})),
			uniqueCountries: Array.from(uniqueCountries).map((c) => ({
				name: c,
				value: c,
			})),
		};
	}, [locations, selectedCountry]);

	return (
		<div className={styles.root}>
			<Input
				className={styles.search}
				defaultValue={q || undefined}
				labelColor="primary"
				labelClassName={styles.searchLabel}
				inputClassName={styles.searchInput}
				label={t("input.search.label")}
				placeholder={t("input.search.placeholder")}
				onChange={(e) => {
					const value = e.target.value;
					if (value) {
						shallowParams.set(RETAILER_FILTER_Q_PARAM, value);
					} else {
						shallowParams.delete(RETAILER_FILTER_Q_PARAM);
					}
				}}
			/>
			<Select
				hideReset={false}
				onReset={() => searchParams.delete(RETAILER_FILTER_COUNTRY_PARAM)}
				large
				onValueChange={(e) => {
					if (!e) return searchParams.delete(RETAILER_FILTER_COUNTRY_PARAM);

					const newParams = new URLSearchParams(searchParams);

					newParams.delete(RETAILER_FILTER_CITY_PARAM);
					newParams.set(RETAILER_FILTER_COUNTRY_PARAM, e);

					router.replace(`${pathname}?${newParams.toString()}`);
				}}
				value={selectedCountry}
				placeholder={t("select.country")}
				items={uniqueCountries}
				label={t("country")}
			/>
			<Select
				hideReset={false}
				onReset={() => searchParams.delete(RETAILER_FILTER_CITY_PARAM)}
				large
				onValueChange={(e) => {
					if (!e) return searchParams.delete(RETAILER_FILTER_CITY_PARAM);

					searchParams.set(RETAILER_FILTER_CITY_PARAM, e);
				}}
				value={selectedCity}
				placeholder={t("select.city")}
				items={uniqueCities}
				label={t("city")}
			/>
		</div>
	);
};
