"use client";

import type { BloksRoute } from "@/data/routes/bloks.route";
import { env } from "@/env.mjs";

import { RetailerBlok } from "@/components/bloks/RetailerBlok";
import {
	MapboxMarkerPopup,
	MapboxMarkerRoot,
	MapboxMarkerTrigger,
	MapboxRoot,
} from "@/components/ui/Mapbox";
import { type MapboxFeature } from "@/components/ui/Mapbox/types";
import React, { Suspense, useEffect } from "react";
import { Skeleton } from "../Skeleton";
import { INITIAL_ZOOM } from "./config";
import styles from "./index.module.css";
import { useLocationStore } from "./useLocationStore";

export type RetailersMapProps = {
	features: MapboxFeature[];
};

export const RetailersMap = ({
	features,
	mapRef,
	bloks,
}: {
	features: MapboxFeature[];
	// eslint-disable-next-line no-undef
	mapRef: React.MutableRefObject<mapboxgl.Map | null>;
	bloks: BloksRoute["retailers"];
}) => {
	const styleUrl = env.NEXT_PUBLIC_MAPBOX_STYLE_URL;
	const accesToken = env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

	const { coordinates, setCoordinates } = useLocationStore();

	useEffect(() => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const coordinates: [number, number] = [
						position.coords.longitude,
						position.coords.latitude,
					];
					setCoordinates(coordinates);
				},
				() => {},
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0,
				},
			);
		}
	}, [setCoordinates]);

	return (
		<Suspense fallback={<RetailersMapSkeleton />}>
			<MapboxRoot
				zoom={INITIAL_ZOOM}
				center={coordinates}
				mapRef={mapRef}
				accessToken={accesToken}
				styleUrl={styleUrl}
				className={styles.map}>
				{features.map((feature, i) => (
					<MapboxMarkerRoot key={i} coordinates={feature.geometry.coordinates}>
						<MapboxMarkerTrigger>
							<div className={styles.trigger}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									fill="none"
									viewBox="0 0 24 24"
									style={{ margin: "5px" }}>
									<circle cx="12" cy="9" r="2.25" strokeWidth="1.5"></circle>
									<path
										strokeWidth="1.5"
										d="M17.25 9c0 .356-.093.88-.287 1.557-.191.665-.465 1.432-.795 2.256-.66 1.648-1.527 3.485-2.348 5.126a2.01 2.01 0 0 1-3.64 0c-.82-1.64-1.687-3.478-2.348-5.126a24 24 0 0 1-.795-2.256C6.843 9.88 6.75 9.356 6.75 9a5.25 5.25 0 0 1 10.5 0Z"></path>
								</svg>
							</div>
						</MapboxMarkerTrigger>
						<MapboxMarkerPopup name={feature.properties.title}>
							<RetailerBlok mapRef={mapRef} blok={bloks[i]!} />
						</MapboxMarkerPopup>
					</MapboxMarkerRoot>
				))}
			</MapboxRoot>
		</Suspense>
	);
};

const RetailersMapSkeleton = () => {
	return <Skeleton className={styles.skeleton} />;
};
