"use client";

import { Asset } from "@/components/bloks/MediaBlok";
import { Close24xLarge } from "@/components/icons";
import { AmountDot } from "@/components/ui/AmountDot";
import { Button } from "@/components/ui/button";
import { ModalClose } from "@/components/ui/modal";
import { Progress } from "@/components/ui/Progress";
import {
	ScrollAreaBar,
	ScrollAreaRoot,
	ScrollAreaViewport,
} from "@/components/ui/ScrollArea";
import { Text } from "@/components/ui/Text";
import { PATHS } from "@/config";
import { useFreeShippingVouchers } from "@/features/Checkout/hooks";
import { clsx, DialogClose } from "@frend-digital/ui";
import { useTranslations } from "next-intl";
import { CartItem } from "../CartItem";

import type { MatchedPaymendMethod } from "@/features/PaymentMethods/dal";
import { getCentraMedia } from "@/lib/utils";
import { useCart, useGeoData } from "../hooks";
import styles from "./index.module.css";
import { ShippingBarSkeleton } from "./ShippingBarSkeleton";

export const CartItems = ({
	checkoutItem,
	hideActions,
}: {
	checkoutItem?: boolean;
	hideActions?: boolean;
}) => {
	const t = useTranslations("cart.modal.content");
	const { data } = useCart();

	if (data?.items?.length === 0) {
		return (
			<Text variant="h4" bold className={styles.empty}>
				{t("empty")}
			</Text>
		);
	}

	return (
		<ScrollAreaRoot>
			<ScrollAreaViewport style={{ height: "100%" }}>
				<div className={styles.items}>
					{data?.items?.map((item, i) => (
						<div
							className={clsx(styles.item, checkoutItem && styles.checkoutItem)}
							key={i}>
							<CartItem
								hideActions={hideActions}
								color={item.product?.variantName}
								media={getCentraMedia(item.product?.media, "full")}
								name={item.product?.name}
								price={item.totalPrice!}
								quantity={item.quantity!}
								discountedPrice={item.totalPriceAfterDiscount}
								uri={item.product?.uri}
								sku={item.sku}
								line={item.line!}
								size={item.size}
							/>
						</div>
					))}
				</div>
			</ScrollAreaViewport>
			<ScrollAreaBar orientation="vertical" />
		</ScrollAreaRoot>
	);
};

export const CartContent = ({
	paymentMethods,
}: {
	paymentMethods: MatchedPaymendMethod[];
}) => {
	const t = useTranslations("cart.modal.content");
	const { data } = useCart();

	return (
		<section className={styles.root}>
			<header className={styles.header}>
				<Text asChild variant="h4" bold>
					<h2>
						{t("header")}{" "}
						<AmountDot variant="beige" size="xSmall">
							{data?.itemCount ?? 0}
						</AmountDot>
					</h2>
				</Text>

				<ModalClose asChild>
					<DialogClose asChild>
						<Button variant="unstyled">
							<Close24xLarge />
						</Button>
					</DialogClose>
				</ModalClose>
			</header>
			<CartItems />
			<footer className={styles.summary}>
				<ShippingBar />
				<section>
					<div className={styles.content}>
						<dl>
							<Text asChild>
								<dd className={styles.count}>
									{t("total")} ({data?.itemCount} {t("products")})
								</dd>
							</Text>

							<dt className="sr-only">Total price</dt>
							<Text asChild>
								<dd className={styles.price}>{data?.total}</dd>
							</Text>
						</dl>

						{data?.items && data?.items?.length > 0 && (
							<Button
								color="yellow"
								size={{
									base: "medium",
									md: "xLarge",
								}}
								href={PATHS.Checkout.Root}>
								<Text variant={{ base: "utility3", md: "utility1" }} weight="500">
									{t("checkout")}
								</Text>
							</Button>
						)}
					</div>
					<div className={styles.paymentOptions}>
						{paymentMethods.map(({ icon }, i) => (
							<div className={styles.paymentOption} key={i}>
								<Asset key={i} source={icon!} className={styles.paymentAsset} />
							</div>
						))}
					</div>
				</section>
			</footer>
		</section>
	);
};

const ShippingBar = () => {
	const t = useTranslations("cart.modal.shipping");

	const {
		data: freeShippingVouchers,
		isLoading: loadingShippingVouchers,
		error: shippingVouchersError,
	} = useFreeShippingVouchers();
	const { data: geoData, isLoading: loadingGeoData, error: geoDataError } = useGeoData();
	const { data: cartData, isLoading: loadingCartData, error: cartDataError } = useCart();

	const isLoading = loadingShippingVouchers || loadingGeoData || loadingCartData;

	if (isLoading) {
		return <ShippingBarSkeleton />;
	}
	const isError = shippingVouchersError || geoDataError || cartDataError;

	// TODO - Document this in Notion
	if (isError) return null;

	const freeShippingCriteria = JSON.parse(
		freeShippingVouchers?.at(0)?.actions?.at(0)?.criteria?.at(1)?.paramsJSON || "{}",
	);

	const requiredCartTotalForFreeShipping = freeShippingCriteria.value[
		Number(geoData?.pricelist)
	] as number;

	const totalCartValue = cartData?.totalPriceAsNumber!;
	const amountToReachFreeShipping = requiredCartTotalForFreeShipping - totalCartValue;

	const hasFreeShipping =
		amountToReachFreeShipping <= 0 ||
		(cartData?.shippingPriceAsNumber === 0 &&
			cartData?.itemCount &&
			cartData?.itemCount > 0);

	const percentToFreeShipping = Math.min(
		(totalCartValue / requiredCartTotalForFreeShipping) * 100,
		100,
	);

	return (
		<section className={clsx(styles.shipping, hasFreeShipping && styles.free)}>
			<div className={styles.shippingTotal}>
				<Text
					variant={{
						base: "utility6",
						md: "utility5",
					}}>
					{t("header")}
				</Text>
				<Text
					type={hasFreeShipping ? "success" : undefined}
					variant={{
						base: "utility4",
						md: "utility1",
					}}
					bold>
					{hasFreeShipping ? t("free") : cartData?.shippingPrice}
				</Text>
			</div>

			<div className={clsx(styles.amountLeft, hasFreeShipping && styles.right)}>
				{hasFreeShipping ? (
					<Text variant={"utility5"} type="success">
						{t("freeActivated")}
					</Text>
				) : (
					<>
						<Text
							variant={{
								base: "utility5",
								md: "utility4",
							}}>
							{amountToReachFreeShipping.toFixed(2)} {geoData?.currency} {t("left")}{" "}
							<Text asChild bold variant="none">
								<span>{t("freeShipping")}</span>
							</Text>
						</Text>
						<Progress max={100} value={percentToFreeShipping} />
					</>
				)}
			</div>
		</section>
	);
};
