"use client";

import { TabBar, type Tab } from "@/components/ui/TabBar";
import type { Dispatch, SetStateAction } from "react";

export type SliderBarTab = {
	title: string;
	id: string;
	link?: string;
};

interface SliderTabBarProps {
	activeTab?: SliderBarTab;
	setActiveTab: Dispatch<SetStateAction<SliderBarTab>>;
	tabs?: Tab<SliderBarTab>[];
}

export const SliderTabBar = ({ activeTab, setActiveTab, tabs }: SliderTabBarProps) => {
	if (!tabs) return null;

	return (
		<TabBar value={activeTab} onValueChange={setActiveTab} size="large" tabs={tabs} />
	);
};
