"use client";

import { FilterBar } from "@/features/products/product-list/components/Filters/FilterBar";
import { ProductGrid } from "@/features/products/product-list/components/ProductGrid";
import { ProductCard } from "@/features/products/ProductCard";
import { WishListIntro } from "@/features/Wishlist/WishlistIntro";
import { useWishlist } from "@/lib/wishlist";
import { FilterBarSkeleton } from "../products/product-list/components/Filters/FilterbarSkeleton";
import { ProductViewSkeleton } from "../products/product-list/components/ProductView";
import styles from "./index.module.css";
import { WishlistIntroSkeleton } from "./WishlistIntroSkeleton";

const Wishlist = ({ title }: { title?: string }) => {
	const { products, isLoading } = useWishlist();

	if (isLoading)
		return (
			<>
				<WishlistIntroSkeleton />
				<section className={styles.products}>
					<FilterBarSkeleton />
					<ProductViewSkeleton />
				</section>
			</>
		);

	return (
		<>
			<WishListIntro title={title} amount={products?.length ?? 0} />
			<section className={styles.products}>
				<FilterBar labels={{}} filters={false} />
				<ProductGrid>
					{products?.map((product) => <ProductCard key={product.id} product={product} />)}
				</ProductGrid>
			</section>
		</>
	);
};

export default Wishlist;
