"use client";

import { clsx } from "@frend-digital/ui";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  createContext,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./Mapbox.module.css";
import "./styles.css";

import type { MapboxContextValue, MapboxRootProps } from "./types";

const MapboxContent = createContext<MapboxContextValue>(null!);

const MapboxRoot = ({
  children,
  accessToken,
  zoom,
  center,
  className,
  styleUrl,
  interactive = true,
  showNavigationControls = true,
  scrollZoom = true,
  doubleClickZoom = true,
  dragPan = true,
  mapRef: externalMapRef,
}: MapboxRootProps) => {
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const navigationControlRef = useRef<mapboxgl.NavigationControl | null>(null);

  useImperativeHandle(externalMapRef, () => mapRef.current!);

  useEffect(() => {
    if (!mapContainerRef.current) return;

    mapboxgl.accessToken = accessToken;
    if (mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center,
      zoom,
      style: styleUrl,
      interactive,
    });

    mapRef.current.on("load", () => {
      setIsMapReady(true);
    });

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [accessToken, center, interactive, styleUrl, zoom]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    map.scrollZoom[scrollZoom ? "enable" : "disable"]();
    map.doubleClickZoom[doubleClickZoom ? "enable" : "disable"]();
    map.dragPan[dragPan ? "enable" : "disable"]();

    map.setCenter(center);
    map.setZoom(zoom);
    if (styleUrl) map.setStyle(styleUrl);

    if (showNavigationControls) {
      if (
        navigationControlRef.current &&
        map.hasControl(navigationControlRef.current)
      ) {
        return;
      }
      navigationControlRef.current = new mapboxgl.NavigationControl();
      map.addControl(navigationControlRef.current);
    } else if (
      navigationControlRef.current &&
      map.hasControl(navigationControlRef.current)
    ) {
      map.removeControl(navigationControlRef.current);
      navigationControlRef.current = null;
    }
  }, [
    center,
    zoom,
    styleUrl,
    interactive,
    scrollZoom,
    doubleClickZoom,
    dragPan,
    showNavigationControls,
  ]);

  return (
    <MapboxContent.Provider value={{ mapRef, isMapReady }}>
      <section className={clsx(styles.mapContainer, className)}>
        <div className={styles.map} ref={mapContainerRef} />
        {children}
      </section>
    </MapboxContent.Provider>
  );
};

const useMapbox = () => {
  const context = useContext(MapboxContent);

  if (!context) {
    throw new Error(
      "Map components must be used within a MapboxRoot component",
    );
  }

  return context;
};

export { MapboxRoot, useMapbox };
