"use client";

import BlokWrap from "@/components/bloks/BlokWrap";
import { type MapboxFeature } from "@/components/ui/Mapbox/types";
import {
	RETAILER_FILTER_Q_PARAM,
	RetailerFilterBar,
} from "@/components/ui/RetailerFilterBar";
import { RetailersMap } from "@/components/ui/Retailers";
import type { BloksRoute } from "@/data/routes/bloks.route";
import type { RetailersStoryblok } from "@/generated/sb/sb-types";
import { type BlokProps } from "@frend-digital/storyblok";
import { matchSorter } from "match-sorter";
import { useSearchParams } from "next/navigation";
import { useDeferredValue, useMemo, useRef } from "react";
import { RetailerBlok } from "../RetailerBlok";
import styles from "./index.module.css";

export type FilterParams = {
	city?: string;
	country?: string;
	q?: string;
};

const retailerKeys: (keyof BloksRoute["retailers"][number])[] = [
	"title",
	"street",
	"email",
];

export const RetailersBlok = ({
	blok,
	preview,
	searchParams,
	data,
}: BlokProps<RetailersStoryblok, BloksRoute["retailers"]> & {
	searchParams?: FilterParams;
}) => {
	// eslint-disable-next-line no-undef
	const mapRef = useRef<mapboxgl.Map | null>(null);

	const city = searchParams?.city;
	const country = searchParams?.country;
	const clientParams = useSearchParams();
	const q = clientParams.get(RETAILER_FILTER_Q_PARAM);

	const reducedData = useMemo(() => {
		return data?.reduce(
			(acc, retailer) => {
				if (retailer.city && retailer.country) {
					const matchCity = city ? retailer.city === city : true;
					const matchCountry = country ? retailer.country === country : true;

					if (matchCity && matchCountry) {
						if (retailer.coordinates.location.lat && retailer.coordinates.location.lng) {
							const formattedAddress = `${retailer.street ?? "Street"} ${retailer.postalCode ?? "Postalcode"} ${retailer.city ?? "City"}, ${retailer.country ?? "Country"}`;

							acc.features.push({
								type: "Feature",
								geometry: {
									type: "Point",
									coordinates: [
										retailer.coordinates.location.lng,
										retailer.coordinates.location.lat,
									],
								},
								properties: {
									title: retailer.title ?? "Retailer",
									description: formattedAddress,
								},
							});
						}

						acc.bloks.push(retailer);
					}

					acc.locations.push({
						city: retailer.city as string,
						country: retailer.country as string,
					});
				}
				return acc;
			},
			{ bloks: [], features: [], locations: [] } as {
				bloks: (typeof data)[number][];
				features: MapboxFeature[];
				locations: FilterParams[];
			},
		);
	}, [city, country, data]);

	const sentinelRef = useRef<HTMLDivElement | null>(null);
	const deferredSearchValue = useDeferredValue(q);

	if (!reducedData) return null;

	const { bloks, features, locations } = reducedData;

	const filteredBloks = matchSorter(bloks, deferredSearchValue || "", {
		keys: retailerKeys,
	});

	return (
		<BlokWrap editable={blok} as="div">
			<section data-layout="wide" className={styles.root}>
				<div ref={sentinelRef} />
				<RetailersMap mapRef={mapRef} features={features} bloks={bloks} />
				<RetailerFilterBar locations={locations} />
				<div className={styles.retailers}>
					{filteredBloks.map((blok, index) => {
						return (
							<RetailerBlok
								sentinelRef={sentinelRef}
								mapRef={mapRef}
								blok={blok}
								key={blok._uid + index}
							/>
						);
					})}
				</div>
			</section>
		</BlokWrap>
	);
};
