"use client";

import { ModalContent, ModalRoot, ModalViewport } from "@/components/ui/modal";
import type { MatchedPaymendMethod } from "@/features/PaymentMethods/dal";
import { useModal } from "@/hooks/useModal";
import { gtagUI } from "@/lib/gtm/components";
import { Slot } from "@frend-digital/ui";
import { Suspense, type ComponentProps } from "react";
import { CartContent } from "../CartContent";
import styles from "./index.module.css";

export const CartModal = ({
	paymentMethods,
}: {
	paymentMethods: MatchedPaymendMethod[];
}) => {
	const { isOpen, setOpen } = useModal("cart");

	return (
		<ModalRoot open={isOpen} onOpenChange={setOpen}>
			{isOpen && <gtagUI.ViewCart />}
			<ModalViewport>
				<ModalContent className={styles.content}>
					<CartContent paymentMethods={paymentMethods} />
				</ModalContent>
			</ModalViewport>
		</ModalRoot>
	);
};

export const UnsuspendedCartModalTrigger = ({
	children,
	asChild,
	...rest
}: ComponentProps<"button"> & { asChild?: boolean }) => {
	const { open } = useModal("cart");

	const Component = asChild ? Slot : "button";
	return (
		<Component
			onClick={() => {
				open();
			}}
			{...rest}>
			{children}
		</Component>
	);
};

export const CartModalTrigger: typeof UnsuspendedCartModalTrigger = (props) => {
	return (
		<Suspense fallback={props.asChild ? props.children : null}>
			<UnsuspendedCartModalTrigger {...props} />
		</Suspense>
	);
};
