"use client";

import {
	AccordionContent,
	AccordionIndicator,
	AccordionItem,
	AccordionRoot,
	AccordionTrigger,
} from "@/components/ui/Accordion";
import { FlagButton } from "@/components/ui/FlagButton";
import { Input } from "@/components/ui/Input";
import { Text } from "@/components/ui/Text";
import {
	type CountryData,
	type GroupedCountries,
	type Region,
} from "@/features/i18n/country-selector/utils";
import type { CountryAutoResponse } from "@frend-digital/centra/next";
import { matchSorter } from "match-sorter";
import { useTranslations } from "next-intl";
import { useState } from "react";
import styles from "./index.module.css";

export const CountrySelect = ({
	userdata,
	sortedCountries,
	popularCountries,
}: {
	userdata: CountryAutoResponse | null;
	sortedCountries?: GroupedCountries;
	popularCountries?: CountryData[];
}) => {
	const [inputValue, setInputValue] = useState<string>("");

	const filterGroupedCountries = (
		query: string,
		countries?: GroupedCountries,
	): GroupedCountries => {
		if (!query) return {} as GroupedCountries;

		return {
			europe: matchSorter(countries?.europe ?? [], query, { keys: ["name", "country"] }),
			other: matchSorter(countries?.other ?? [], query, { keys: ["name", "country"] }),
		};
	};

	const filteredCountries = inputValue
		? filterGroupedCountries(inputValue, sortedCountries)
		: sortedCountries;

	const t = useTranslations("i18n.country");
	const initialCountry = userdata?.country?.country;

	return (
		<fieldset className={styles.content}>
			<div className={styles.countriesHeader}>
				<Text variant="h4" bold>
					{t("header")}
				</Text>
				<Text variant="body2">{t("subHeader")}</Text>
			</div>
			{!!popularCountries?.length && (
				<div className={styles.popular}>
					{popularCountries.map((country, i) => (
						<FlagButton
							selected={initialCountry === country.country}
							text={country.name}
							countryCode={country.country}
							key={i}
						/>
					))}
				</div>
			)}
			<div className={styles.regions}>
				<Text variant="utility3">{t("regions")}</Text>
				<Input
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					placeholder={t("search")}
					className={styles.search}
				/>
				<AccordionRoot className={styles.accordionRoot}>
					{filteredCountries &&
						Object.keys(filteredCountries).map((k, i) => {
							const region = k as Region;
							return (
								<AccordionItem open key={i} className={styles.accordionItem}>
									<AccordionTrigger className={styles.accordionTrigger}>
										<Text variant="body2">{region}</Text>
										<AccordionIndicator />
									</AccordionTrigger>
									<AccordionContent className={styles.accordionContent}>
										{filteredCountries[region]?.map((country, j) => (
											<FlagButton
												selected={initialCountry === country.country}
												text={country.name}
												countryCode={country.country}
												key={`${i}-${j}`}
											/>
										))}
									</AccordionContent>
								</AccordionItem>
							);
						})}
				</AccordionRoot>
			</div>
		</fieldset>
	);
};
