"use client";

import { ACTIVE_RETAILER_KEY } from "@/components/bloks/RetailersBlok/config";
import { Slot } from "@/components/ui/Slot";
import { useHydrated } from "@frend-digital/ui/hooks";
import { useQueryParams } from "@frend-digital/ui/next";
import * as Portal from "@radix-ui/react-portal";
import mapboxgl, { Popup } from "mapbox-gl";
import { createContext, useContext, useEffect, useId, useState } from "react";
import { useMapbox } from "./Mapbox";
import type {
	MapboxMarkerContextValue,
	MapboxMarkerPopupProps,
	MapboxMarkerProps,
	MapboxMarkerTriggerProps,
} from "./types";

const MapboxMarkerContext = createContext<MapboxMarkerContextValue>(null!);

const MapboxMarkerRoot = ({ coordinates, children }: MapboxMarkerProps) => {
	const [markerElement, setMarkerElement] = useState<HTMLDivElement | null>(null);
	const [popupElement, setPopupElement] = useState<Popup | null>(null);
	const [open, setOpen] = useState<boolean>(false);

	return (
		<MapboxMarkerContext.Provider
			value={{
				open,
				setOpen,
				coordinates,
				markerElement,
				setMarkerElement,
				popupElement,
				setPopupElement,
			}}>
			{children}
		</MapboxMarkerContext.Provider>
	);
};

const useMapboxMarker = () => {
	const context = useContext(MapboxMarkerContext);

	if (!context) {
		throw new Error(
			"MapMarker components must be used within a MapboxMarkerRoot component",
		);
	}

	return context;
};

const MapboxMarkerTrigger = ({
	flyToOnClick = true,
	flyToZoom = 14,
	children,
	className,
	style,
}: MapboxMarkerTriggerProps) => {
	const hydrated = useHydrated();
	const markerId = useId();
	const { mapRef, isMapReady } = useMapbox();
	const { setMarkerElement, coordinates, open } = useMapboxMarker();

	useEffect(() => {
		if (!isMapReady || !mapRef.current) return;

		const markerElement = document.createElement("div");
		markerElement.id = markerId;

		setMarkerElement(markerElement);
	}, [isMapReady, coordinates, markerId, mapRef, setMarkerElement]);

	const handleMarkerClick = () => {
		if (flyToOnClick && mapRef.current) {
			mapRef.current.flyTo({
				center: coordinates,
				zoom: flyToZoom,
			});
		}
	};

	if (!hydrated) return null;

	return (
		<Portal.Root container={document.getElementById(markerId)} asChild>
			<Slot
				className={className}
				style={style}
				data-open={open}
				onClick={handleMarkerClick}>
				{children}
			</Slot>
		</Portal.Root>
	);
};

const MapboxMarkerPopup = ({
	popupOffset = 25,
	children,
	className,
	style,
	name,
}: MapboxMarkerPopupProps & {
	name: string;
}) => {
	const searchParams = useQueryParams({
		shallow: false,
	});
	const isActive = searchParams.get(ACTIVE_RETAILER_KEY) === name;

	const hydrated = useHydrated();
	const popupId = useId();
	const { mapRef, isMapReady } = useMapbox();
	const { markerElement, open, setOpen, setPopupElement, coordinates } =
		useMapboxMarker();

	useEffect(() => {
		if (!isMapReady || !mapRef.current || !markerElement) return;

		const popup = new mapboxgl.Popup({ offset: popupOffset }).setHTML(
			`<div id="${popupId}"></div>`,
		);
		setPopupElement(popup);

		const openHandler = () => {
			setOpen(true);
		};

		const closeHandler = () => {
			setOpen(false);
		};

		popup.on("open", openHandler);

		popup.on("close", closeHandler);

		const marker = new mapboxgl.Marker(markerElement)
			.setLngLat(coordinates)
			.setPopup(popup)
			.addTo(mapRef.current);

		if (isActive) {
			marker.togglePopup();
		}

		return () => {
			popup.off("open", openHandler);
			popup.off("close", closeHandler);
			marker.remove();
		};
	}, [
		isMapReady,
		coordinates,
		popupId,
		popupOffset,
		mapRef,
		markerElement,
		setPopupElement,
		setOpen,
		isActive,
	]);

	if (!hydrated || !open) return null;

	return (
		<Portal.Root container={document.getElementById(popupId)} asChild>
			<Slot className={className} style={style} data-open={open}>
				{children}
			</Slot>
		</Portal.Root>
	);
};

export { MapboxMarkerPopup, MapboxMarkerRoot, MapboxMarkerTrigger };
