"use client";

import type { ButtonStoryblok } from "@/generated/sb/sb-types";
import { useEffect, useRef } from "react";
import styles from "../index.module.css";

interface AnimatedTagsProps {
	tags: ButtonStoryblok[];
}

export const AnimatedTags = ({ tags }: AnimatedTagsProps) => {
	const tagsRef = useRef<(HTMLSpanElement | null)[]>([]);

	useEffect(() => {
		tagsRef.current.forEach((tag, index) => {
			if (tag) {
				const totalDuration = tags.length * 4000;
				tag.style.animation = `${styles.animation} ${totalDuration}ms linear infinite`;
				tag.style.animationDelay = `${index * 4000}ms`;
			}
		});
	}, [tags]);

	const setRef = (index: number) => (el: HTMLSpanElement | null) => {
		tagsRef.current[index] = el;
	};
	return (
		<div className={styles.tags}>
			{tags.map((tag, index) => (
				<span key={index} ref={setRef(index)} className={styles.tag}>
					{tag.text}
				</span>
			))}
		</div>
	);
};
