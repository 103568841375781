import { create } from "zustand";
import { persist } from "zustand/middleware";

interface LocationStore {
	coordinates: [number, number];
	setCoordinates: (coordinates: [number, number]) => void;
}

export const useLocationStore = create<LocationStore>()(
	persist(
		(set) => ({
			coordinates: [10.7804, 59.9002],
			setCoordinates: (coordinates) => set({ coordinates }),
		}),
		{
			name: "location-store",
		},
	),
);
