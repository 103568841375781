import { type DoNotUseStoryblok } from "@/blok-types";
import { defineStoryblok } from "@frend-digital/storyblok";
import { notFound } from "next/navigation";

import { AccordionBlok } from "./AccordionBlok";
import { AccordionItemBlok } from "./AccordionItemBlok";
import { ArticleBlok } from "./ArticleBlok";
import { ButtonBlok } from "./ButtonBlok";
import { ContentCardBlok } from "./ContentCardBlok";
import { ContentCardsBlok } from "./ContentCardsBlok";
import { DisplayCardBlok } from "./DisplayCardBlok";
import { DisplayCardsBlok } from "./DisplayCardsBlok";
import { HeroBlok } from "./HeroBlok";

import { rpc } from "#rpc";
import { IntroBlok } from "./IntroBlok";
import { MediaBlok } from "./MediaBlok";
import { NewsletterSignupBlok } from "./NewsletterSignupBlok/NewsletterSignupBlok";
import { PageBlok } from "./PageBlok";
import { ProductBlok } from "./ProductBlok";
import { ProductListingPageBlok } from "./ProductListingPageBlok";
import { ProductPageBlok } from "./ProductPageBlok";
import { ProductSliderBlok } from "./ProductSliderBlok";
import { QuoteBlok } from "./QuoteBlok";
import { RetailersBlok } from "./RetailersBlok";
import { RichTextBlok } from "./RichTextBlok";
import { SideBySideBlok } from "./SideBySideBlok";
import { TagCloudBlok } from "./TagCloudBlok";
import { TaggedImageBlok } from "./TaggedImageBlok";
import { WishlistBlok } from "./WishlistBlok";

type AllBloks = Exclude<
	NonNullable<DoNotUseStoryblok["body"]>[number],
	DoNotUseStoryblok
>;

const NotFound = () => {
	notFound();
};

defineStoryblok<AllBloks>({
	bloks: {
		articleListingPage: () => null,
		footer: () => null,
		globalConfig: () => null,
		headerLinks: () => null,
		headerSection: () => null,
		headerSectionTags: () => null,
		mobileLink: () => null,
		mobileMenuButton: () => null,
		retailer: () => null,
		searchPage: () => null,
		searchBar: () => null,
		snackbar: () => null,
		productSliderItem: () => null,
		modal: () => null,

		product: {
			blok: ProductBlok,
			fetcher: rpc.bloks.product,
			getKey: ({ blok }) => blok.productId || "",
		},
		productSlider: {
			blok: ProductSliderBlok,
			fetcher: rpc.bloks.sliderProducts,
			// @ts-ignore
			getKey: ({ blok }) => blok.products || [],
		},
		retailers: {
			blok: RetailersBlok,
			fetcher: rpc.bloks.retailers,
			getKey: ({ blok }) => blok.component,
		},
		taggedImage: {
			blok: TaggedImageBlok,
			fetcher: rpc.bloks.taggedImage,
			getKey: ({ blok }) => JSON.stringify(blok),
		},

		accordion: AccordionBlok,
		accordionItem: AccordionItemBlok,
		article: ArticleBlok,
		button: ButtonBlok,
		contentCard: ContentCardBlok,
		contentCards: ContentCardsBlok,
		displayCard: DisplayCardBlok,
		displayCards: DisplayCardsBlok,
		footerLinks: NotFound,
		hero: HeroBlok,
		intro: IntroBlok,
		media: MediaBlok,
		newsletterSignup: NewsletterSignupBlok,
		navigation: NotFound,
		page: PageBlok,
		productListingPage: ProductListingPageBlok,
		productPage: ProductPageBlok,
		qoute: QuoteBlok,
		richText: RichTextBlok,
		sideBySide: SideBySideBlok,
		tagCloud: TagCloudBlok,
		wishlistPage: WishlistBlok,
	},
});
