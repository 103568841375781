import BlokWrap from "@/components/bloks/BlokWrap";
import { ProductSlider } from "@/components/ui/ProductSlider";
import type { BloksRoute } from "@/data/routes/bloks.route";
import type { ProductSliderStoryblok } from "@/generated/sb/sb-types";
import type { BlokProps } from "@frend-digital/storyblok";

export const ProductSliderBlok = ({
	blok,
	data,
	preview,
}: BlokProps<ProductSliderStoryblok, BloksRoute["sliderProducts"]>) => {
	return (
		<BlokWrap layout="full" editable={blok} as="div">
			{data && <ProductSlider storyId={blok._uid} title={blok.title} sections={data} />}
		</BlokWrap>
	);
};
