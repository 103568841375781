import type { SliderBarTab } from "@/components/bloks/ProductSliderBlok/SliderTabBar";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface ProductSliderActiveFilter {
	items: Map<string, SliderBarTab>;
	add: (storyId: string, activeFilterTab: SliderBarTab) => void;
	remove: (storyId: string) => void;
	clear: () => void;
}

export const useProductSliderActiveFilter = create(
	persist<ProductSliderActiveFilter>(
		(set) => ({
			items: new Map(),
			add: (storyId, activeFilterTab) => {
				set((state) => {
					const items = new Map(state.items);
					items.set(storyId, activeFilterTab);
					return { items };
				});
			},
			remove: (storyId) => {
				set((state) => {
					const items = new Map(state.items);
					items.delete(storyId);
					return { items };
				});
			},
			clear: () => {
				set({ items: new Map() });
			},
		}),
		{
			name: "productSliderFilters",
			storage: {
				getItem: (name) => {
					const str = localStorage.getItem(name);
					if (!str) return null;
					const storedState = JSON.parse(str);
					return {
						state: {
							...storedState.state,
							items: new Map(Object.entries(storedState.state.items)),
						},
					};
				},
				setItem: (name, value) => {
					const serializedValue = JSON.stringify({
						state: {
							...value.state,
							items: Object.fromEntries(value.state.items),
						},
					});
					localStorage.setItem(name, serializedValue);
				},
				removeItem: (name) => {
					localStorage.removeItem(name);
				},
			},
		},
	),
);
