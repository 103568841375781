import BlokWrap from "@/components/bloks/BlokWrap";
import { INITIAL_ZOOM } from "@/components/ui/Retailers/config";
import { Tag } from "@/components/ui/Tag";
import { Text } from "@/components/ui/Text";
import type { BloksRoute } from "@/data/routes/bloks.route";
import { useQueryParams } from "@frend-digital/ui/next";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { ACTIVE_RETAILER_KEY } from "../RetailersBlok/config";
import styles from "./index.module.css";

export const RetailerBlok = ({
	blok,
	mapRef,
	sentinelRef,
}: {
	blok: BloksRoute["retailers"][number];
	// eslint-disable-next-line no-undef
	mapRef: React.MutableRefObject<mapboxgl.Map | null>;
	sentinelRef?: React.RefObject<HTMLDivElement | null>;
}) => {
	const t = useTranslations("retailer");
	const searchParams = useQueryParams();
	const tag = `${blok.country ?? "Country"}, ${blok.city ?? "City"}`;

	const formattedAddress = `${blok.street} ${blok.postalCode} ${blok.city}, ${blok.country}`;

	const scrollToTop = () => {
		if (sentinelRef?.current) {
			const elementPosition =
				sentinelRef.current.getBoundingClientRect().top + window.scrollY;
			const headerHeight = getComputedStyle(document.documentElement).getPropertyValue(
				"--header-min-height",
			);

			const offsetPosition = elementPosition - Number(headerHeight.replace("px", ""));

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	};

	return (
		<BlokWrap
			dataBlok={false}
			editable={blok}
			as="article"
			className={styles.root}
			onClick={() => {
				if (!sentinelRef) return;
				scrollToTop();
				mapRef.current?.flyTo({
					center: [
						Number(blok.coordinates.location.lng),
						Number(blok.coordinates.location.lat),
					],
					zoom: INITIAL_ZOOM,
				});
				searchParams.set(ACTIVE_RETAILER_KEY, blok.title);
			}}>
			<div className={styles.tag}>
				<Tag size="xSmall" variant="beige">
					<Text variant="utility5" bold>
						{tag}
					</Text>
				</Tag>
			</div>
			<header>
				<Text variant="h4" bold>
					{blok.title}
				</Text>
				<Link
					href={`mailto:${blok.email}`}
					target="_blank"
					style={{ textDecoration: "none" }}>
					<Text variant="body2" color="primary-dark-blue-08" className={styles.link}>
						{blok.email}
					</Text>
				</Link>
				<Link
					href={`tel:${blok.phone}`}
					target="_blank"
					style={{ textDecoration: "none" }}>
					<Text variant="body2" color="primary-dark-blue-08">
						{blok.phone}
					</Text>
				</Link>
			</header>
			<div className={styles.address}>
				<Text variant="body2">{formattedAddress}</Text>
				{/* <Button variant="unstyled">
					<Text variant="body2" color="primary-dark-blue-08" className={styles.link}>
						<Translate value={"map.view"} /> 
					</Text>
				</Button> */}
			</div>
			<Text variant="utility5" bold>
				{t("openingHours")}
			</Text>
			<div className={styles.openingHours}>
				<Text variant="utility6">
					{t("monday")}: {blok.monday}
				</Text>
				<Text variant="utility6">
					{t("tuesday")}: {blok.tuesday}
				</Text>
				<Text variant="utility6">
					{t("wednesday")}: {blok.wednesday}
				</Text>
				<Text variant="utility6">
					{t("thursday")}: {blok.thursday}
				</Text>
				<Text variant="utility6">
					{t("friday")}: {blok.friday}
				</Text>
				<Text variant="utility6">
					{t("saturday")}: {blok.saturday}
				</Text>
				<Text variant="utility6">
					{t("sunday")}: {blok.sunday}
				</Text>
			</div>
		</BlokWrap>
	);
};
