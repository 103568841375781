"use client";

import {
	Select as AriakitSelect,
	SelectArrow,
	SelectItem,
	SelectLabel,
	SelectPopover,
	SelectProvider,
	SelectValue,
} from "@ariakit/react";
import { CountryFlag } from "@frend-digital/centra/location";
import { clsx } from "@frend-digital/ui";
import { useControllableState } from "@frend-digital/ui/hooks";
import { useTranslations } from "next-intl";
import React, { forwardRef, type ComponentProps, type HTMLAttributes } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ScrollAreaBar, ScrollAreaRoot, ScrollAreaViewport } from "../ScrollArea";
import { Text } from "../Text";
import styles from "./index.module.css";

type Item = {
	name: string;
	value: string;
};

type SelectProps = {
	items: Item[];
	onValueChange?: (e: string) => void;
	value?: string | null;
	placeholder?: string;
	label?: React.ReactNode;
	hideReset?: boolean;
	resetPlaceholder?: string;
	name?: string;
	defaultValue?: string;
	renderItem?: (item: Item) => React.ReactNode;
	renderLabel?: () => React.ReactNode;
	large?: boolean;
	className?: string;
	error?: string;
	onReset?: () => void;
};

export const Select = ({
	items,
	onValueChange,
	placeholder,
	label,
	hideReset = true,
	resetPlaceholder,
	name,
	defaultValue: externalDefaultValue,
	value: externalValue,
	renderItem,
	renderLabel,
	large,
	className,
	error,
	onReset,
}: SelectProps) => {
	const t = useTranslations("select");

	const [value, setValue] = useControllableState(
		externalValue ?? "",
		externalDefaultValue ?? undefined,
	);

	const reset = () => {
		setValue("");

		onReset?.();
	};

	const handleChange = (newValue: string) => {
		if (onValueChange) onValueChange(newValue);

		setValue(newValue);
	};
	return (
		<SelectProvider setValue={handleChange} value={value}>
			<div className={clsx(styles.root, large && styles.large, className)}>
				{(label || renderLabel) && !error && (
					<SelectLabel>
						{renderLabel ? (
							renderLabel()
						) : (
							<Text variant="utility5" color="primary" className={styles.label}>
								{label}
							</Text>
						)}
					</SelectLabel>
				)}

				{error && (
					<SelectLabel>
						<Text variant="utility5" color="red" className={styles.label}>
							{error}
						</Text>
					</SelectLabel>
				)}
				<AriakitSelect className={styles.primitive} name={name}>
					<div className={styles.trigger}>
						<SelectValue fallback={placeholder} />
					</div>
					<SelectArrow />
				</AriakitSelect>

				<SelectPopover sameWidth unmountOnHide className={styles.options}>
					<ScrollAreaRoot className={styles.scrollRoot}>
						<ScrollAreaViewport className={styles.scrollViewport}>
							{!hideReset && value && (
								<SelectItem
									className={styles.option}
									onClick={() => onReset?.()}
									value="">
									<Text variant="utility4">{resetPlaceholder ?? t("reset")}</Text>
								</SelectItem>
							)}

							{items.map((item, i) => (
								<SelectItem key={i} value={item.value}>
									{renderItem ? (
										renderItem(item)
									) : (
										<div className={styles.option}>
											<div className={styles.radioButton} />
											<Text variant="utility4">{item.name}</Text>
										</div>
									)}
								</SelectItem>
							))}
						</ScrollAreaViewport>
						<ScrollAreaBar className={styles.bar} orientation="vertical" />
					</ScrollAreaRoot>
				</SelectPopover>
			</div>
		</SelectProvider>
	);
};

// export const Combobox = ({
// 	searchPlaceholder,
// 	items,
// 	useValue,
// 	...props
// }: ComponentProps<typeof Select> & { searchPlaceholder: string; useValue?: boolean }) => {
// 	const [search, setSearch] = useState("");
// 	const deferredSearch = useDeferredValue(search);

// 	const filteredItems = useMemo(() => {
// 		return items.filter((item) => {
// 			const nameCond = item.name.toLowerCase().includes(deferredSearch.toLowerCase());
// 			if (useValue)
// 				return (
// 					nameCond || item.value.toLowerCase().includes(deferredSearch.toLowerCase())
// 				);
// 			return nameCond;
// 		});
// 	}, [deferredSearch, items, useValue]);

// 	return (
// 		<Select items={filteredItems} {...props}>
// 			<input
// 				type="search"
// 				onChange={(e) => setSearch(e.target.value)}
// 				className={styles.comboboxInput}
// 				placeholder={searchPlaceholder}
// 			/>
// 		</Select>
// 	);
// };

export const SelectItemWithFlag = forwardRef<
	HTMLDivElement,
	{ item: Item } & HTMLAttributes<HTMLDivElement>
>(({ item, ...props }, ref) => {
	return (
		<div ref={ref} {...props} className={clsx(styles.option, props.className)}>
			<div className={styles.radioButton} />
			<CountryFlag countryCode={item.value!} />
			<Text variant="utility4">{item.name}</Text>
		</div>
	);
});

SelectItemWithFlag.displayName = "SelectItemWithFlag";

interface SelectFieldProps extends Omit<ComponentProps<typeof Select>, "name"> {
	name: string;
}

export const SelectField = ({ name, ...rest }: SelectFieldProps) => {
	const { control } = useFormContext();
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Select
					error={error?.message}
					{...rest}
					name={field.name}
					value={field.value}
					onValueChange={field.onChange}
				/>
			)}
		/>
	);
};

// interface ComboBoxFieldProps extends Omit<ComponentProps<typeof Combobox>, "name"> {
// 	name: string;
// }

// export const ComboboxField = ({ items, ...props }: ComboBoxFieldProps) => {
// 	const { control } = useFormContext();
// 	return (
// 		<Controller
// 			name={props.name}
// 			control={control}
// 			render={({ field, fieldState: { error } }) => (
// 				<Combobox
// 					items={items}
// 					error={error?.message}
// 					{...props}
// 					name={field.name}
// 					value={field.value}
// 					onValueChange={field.onChange}
// 				/>
// 			)}
// 		/>
// 	);
// };
