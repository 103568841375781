"use client";

import { Arrow32, Chevron, Close, Menu } from "@/components/icons";
import {
	AccordionContent,
	AccordionItem,
	AccordionRoot,
	AccordionTrigger,
} from "@/components/ui/Accordion";
import { Breadcrumbs } from "@/components/ui/Breadcrumbs";
import { Button } from "@/components/ui/button";
import { CategoryButton } from "@/components/ui/CategoryButton";
import { ModalContent, ModalRoot, ModalViewport } from "@/components/ui/modal";
import { ScrollAreaBar, ScrollAreaRoot } from "@/components/ui/ScrollArea";
import { Text } from "@/components/ui/Text";
import { useDevice } from "@/features/Device";
import type {
	ArticleStoryblok,
	HeaderSectionStoryblok,
	MobileLinkStoryblok,
	MobileMenuButtonStoryblok,
} from "@/generated/sb/sb-types";
import { getLink, type LinkReturn } from "@frend-digital/storyblok";
import { clsx } from "@frend-digital/ui";
import { useQueryParams } from "@frend-digital/ui/next";
import { ScrollAreaViewport } from "@radix-ui/react-scroll-area";

import { useWindowResize } from "@frend-digital/ui/hooks";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import {
	Fragment,
	Suspense,
	useCallback,
	useEffect,
	useRef,
	type ReactNode,
} from "react";
import type { ISbStoryData } from "storyblok-js-client";
import { FeaturedStory } from "../FeaturedStory";
import { useMobileMenu } from "./hooks";
import styles from "./index.module.css";

const MENU_QUERY_KEY = "menu";

export const ViewMoreButton = ({ accordionParent }: { accordionParent?: string }) => {
	const t = useTranslations("header.mobile");
	const searchParams = useQueryParams();
	const { close } = useMobileMenu();

	const params = searchParams
		.getAll(MENU_QUERY_KEY)
		.map((item) => item.toLowerCase().replace(" & ", "-and-"));

	if (accordionParent) {
		params.splice(1, 0, accordionParent.toLowerCase().replace(" & ", "-and-"));
	}

	return (
		<Button
			href={`/products/${params.join("/")}`}
			onClick={() => {
				close();
			}}
			color="greige"
			size="small">
			<Text variant="utility4">{t("view")}</Text>
		</Button>
	);
};

const RecursiveComponent = ({
	accordionParent,
	link,
}: {
	accordionParent: string;
	link: MobileLinkStoryblok;
}) => {
	const { close } = useMobileMenu();
	const searchParams = useQueryParams();

	const isOpen = searchParams.has(MENU_QUERY_KEY, link.title);

	const href = getLink(link.link)?.href;

	if (!link.links?.length && href) {
		return (
			<Link href={href}>
				<CategoryButton
					className={styles.categoryButton}
					color="greige"
					style={{ border: "none", height: "56px" }}
					pointer
					text={link.title}
					imageSource={link.image?.filename}
					onClick={close}
				/>
			</Link>
		);
	}

	return (
		<nav id={`RECURSIVE-${link._uid}`}>
			<CategoryButton
				className={styles.categoryButton}
				color="greige"
				style={{ border: "none", height: "56px" }}
				pointer
				onClick={() => {
					searchParams.append(MENU_QUERY_KEY, link.title!);
				}}
				text={link.title}
				imageSource={link.image?.filename}
			/>
			<div className={clsx(styles.activeMenu, isOpen && styles.active)}>
				<div className={styles.menuHeader}>
					<Text asChild variant="h2" bold>
						<h2>{link.title}</h2>
					</Text>
					<ViewMoreButton accordionParent={accordionParent} />
				</div>
				{link.links?.map((l2) => (
					<Fragment key={l2._uid}>
						<RecursiveComponent accordionParent={accordionParent} link={l2} />
					</Fragment>
				))}
			</div>
		</nav>
	);
};

export const MobileLinkRoot = ({ mobileLink }: { mobileLink: MobileLinkStoryblok }) => {
	const searchParams = useQueryParams();
	const rootRef = useRef<HTMLDivElement>(null!);

	const { close } = useMobileMenu();

	useEffect(() => {
		const rootElement = rootRef.current;

		const closeMenu = (e: MouseEvent) => {
			const target = e.target as HTMLDivElement;
			if (target.closest("a")) close();
		};

		rootElement?.addEventListener("click", closeMenu);

		return () => {
			rootElement?.removeEventListener("click", closeMenu);
		};
	}, [close, rootRef]);

	if (!mobileLink.title) return null;

	const isOpen = searchParams.has(MENU_QUERY_KEY, mobileLink.title);

	const allParams = searchParams.getAll(MENU_QUERY_KEY);

	const hasLinks = mobileLink?.links?.length;

	return (
		<>
			<div
				ref={rootRef}
				className={styles.mobileLinkRoot}
				onClick={() => {
					if (!hasLinks) return;

					searchParams.append(MENU_QUERY_KEY, mobileLink.title!);
				}}>
				{!hasLinks ? (
					<Link href={getLink(mobileLink.link)?.href ?? "/"}>
						<Text variant="h2" bold>
							{mobileLink.title}
						</Text>
					</Link>
				) : (
					<div className={styles.menuHeader} style={{ marginBottom: 0 }}>
						<Text variant="h2" bold>
							{mobileLink.title}
						</Text>
						<Arrow32 />
					</div>
				)}
			</div>
			<div className={clsx(styles.activeMenu, isOpen && styles.active)}>
				<div className={styles.menuHeader}>
					<Text asChild variant="h2" bold>
						<h2>{allParams[allParams.length - 1]}</h2>
					</Text>
					<ViewMoreButton />
				</div>
				<AccordionRoot
					style={{
						gap: "0px",
					}}
					className={styles.accordionRoot}>
					{mobileLink.links?.map((link, index) => {
						return (
							<AccordionItem
								open={index === 0}
								className={styles.accordionItem}
								key={link._uid}>
								<AccordionTrigger className={styles.accordionTrigger}>
									<Text
										variant="h4"
										key={link._uid}
										style={{ fontWeight: 500, padding: "6px 10px" }}>
										{link.title}
									</Text>
									<Chevron className={styles.chevron} />
								</AccordionTrigger>
								<AccordionContent className={styles.accordionContent}>
									{link.links?.map((l1) => {
										return (
											<RecursiveComponent
												accordionParent={link.title!}
												key={l1._uid}
												link={l1}
											/>
										);
									})}
								</AccordionContent>
							</AccordionItem>
						);
					})}
				</AccordionRoot>
			</div>
		</>
	);
};

export const MobileMenuTrigger = () => {
	const { isOpen, open, close } = useMobileMenu();

	const handleOpen = useCallback(() => {
		if (isOpen) {
			return close();
		}
		return open();
	}, [isOpen, open, close]);

	return (
		<Button
			mobile-menu={isOpen ? "open" : "closed"}
			onClick={handleOpen}
			variant="unstyled"
			type="button"
			title="Toggle mobile menu">
			<>
				{isOpen ? (
					<Close className={styles.closeIcon} width={24} height={24} />
				) : (
					<Menu className={styles.menuIcon} />
				)}
			</>
		</Button>
	);
};

export const UnsuspendedMobileMenu = ({
	children,
	sections,
	featuredStories,
	actionLinks,
}: {
	children: ReactNode;
	sections: ISbStoryData<HeaderSectionStoryblok>[];
	featuredStories?: ISbStoryData<ArticleStoryblok>[];
	actionLinks?: MobileMenuButtonStoryblok[];
}) => {
	const searchParams = useQueryParams();

	const openMenus = searchParams.getAll(MENU_QUERY_KEY);
	const { isOpen, close } = useMobileMenu();

	const handlePreviousMenu = (currentItem: string) => {
		const lastMenu = openMenus[openMenus.length - 1];
		if (!lastMenu || currentItem === lastMenu) return;
		searchParams.delete(MENU_QUERY_KEY, lastMenu);
	};

	const allParams = searchParams.getAll(MENU_QUERY_KEY);

	const breadcrumbs = allParams.map((breadcrumb) => ({
		title: breadcrumb,
		onClick: () => handlePreviousMenu(breadcrumb),
	}));

	const t = useTranslations("header");

	const filteredFeaturedStories = featuredStories?.filter(
		(story, index, self) => index === self.findIndex((s) => s.uuid === story.uuid),
	);

	const { tablet } = useDevice();

	useEffect(() => {
		if (tablet) {
			close();
		}
	}, [tablet, close]);

	useWindowResize(() => {
		close();
	});

	// useEffect(() => {
	// 	if (!isOpen) searchParams.delete(MENU_QUERY_KEY);
	// }, [isOpen, searchParams]);

	return (
		<ModalRoot open={isOpen} mode="modal" onOpenChange={close}>
			<ModalViewport backdrop={false}>
				<ModalContent className={styles.mobileMenu}>
					<Breadcrumbs
						style={{
							paddingLeft: 0,
						}}
						breadcrumbs={
							allParams.length === 0
								? []
								: [
										{
											title: t("menu"),
											onClick: () => searchParams.delete(MENU_QUERY_KEY),
										},
										...breadcrumbs,
									]
						}
					/>
					<section className={styles.menuContainer}>
						{sections.map((section) => {
							if (!section.content.mobileLink?.length) return null;

							return (
								<MobileLinkRoot
									key={section.uuid}
									mobileLink={section.content.mobileLink[0]!}
								/>
							);
						})}

						<div className={styles.actionLinks}>
							{actionLinks?.map(function (link) {
								const href = getLink(link.link) as LinkReturn & { linkType: string };
								return (
									<Link
										key={link._uid}
										href={href?.linkType === "story" ? href?.href : `${href?.rawHref}`}>
										<div className={styles.actionLink}>
											{link.icon?.filename && (
												<Image
													src={link.icon?.filename}
													alt={link.icon?.alt ?? ""}
													width={24}
													height={24}
												/>
											)}
											<Text variant="utility2">{link.text}</Text>
										</div>
									</Link>
								);
							})}
							{children}
						</div>

						<ScrollAreaRoot type="auto" className={styles.storiesRoot}>
							<ScrollAreaViewport>
								<div className={styles.storiesWrapper}>
									{filteredFeaturedStories?.map((story) => (
										<FeaturedStory
											key={story.id}
											story={story}
											className={styles.mobileStory}
										/>
									))}
								</div>
							</ScrollAreaViewport>
							<ScrollAreaBar orientation="horizontal" />
						</ScrollAreaRoot>
					</section>
				</ModalContent>
			</ModalViewport>
		</ModalRoot>
	);
};

export const MobileMenu: typeof UnsuspendedMobileMenu = (props) => {
	return (
		<Suspense fallback={null}>
			<UnsuspendedMobileMenu {...props} />
		</Suspense>
	);
};
