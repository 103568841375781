"use client";

import {
	SliderTabBar,
	type SliderBarTab,
} from "@/components/bloks/ProductSliderBlok/SliderTabBar";
import { Arrow24 } from "@/components/icons";
import type { BloksRoute } from "@/data/routes/bloks.route";
import {
	ProductCarousel,
	ProductSliderCarouselSkeleton,
} from "@/features/products/ProductCarousel";

import { useTranslations } from "next-intl";
import Link from "next/link";
import { FirstWordBold } from "../FirstWordBold";
import { Skeleton } from "../Skeleton";
import { TabBarSkeleton } from "../TabBar";
import { Text } from "../Text";
import styles from "./index.module.css";
import { useProductSliderActiveFilter } from "./useProductSliderActiveFilter";

export interface ProductSliderProps {
	title?: string;
	storyId: string;
	sections: BloksRoute["sliderProducts"];
}

export const ProductSliderSkeleton = () => {
	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<Text variant="h3" className={styles.title} data-layout="page">
					<Skeleton fitContent as="span">
						<FirstWordBold>Lorem ipsum</FirstWordBold>
					</Skeleton>
				</Text>
				<TabBarSkeleton />
			</div>
			<ProductSliderCarouselSkeleton />
		</div>
	);
};

export const ProductSlider = ({ title, sections, storyId }: ProductSliderProps) => {
	const t = useTranslations("products");

	const { items, add } = useProductSliderActiveFilter();

	const activeTab = items.get(storyId);

	const products = sections?.flatMap((section) => section.products);

	if (!products) return null;

	const displayProducts = activeTab
		? sections?.find((section) => section.id === activeTab.id)?.products
		: products;

	const tabs = sections
		?.map((section) => {
			return {
				name: section.title,
				value: {
					title: section.title,
					id: section.id,
					link: section.link,
				},
			};
		})
		.filter(Boolean);

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				{title && (
					<Text variant="h3" className={styles.title} data-layout="page">
						<FirstWordBold>{title}</FirstWordBold>
					</Text>
				)}
				<SliderTabBar
					activeTab={activeTab}
					setActiveTab={(tab) => {
						add(storyId, tab as SliderBarTab);
					}}
					tabs={tabs}
				/>
			</div>
			{sections && (
				<ProductCarousel
					options={{ slidesToScroll: 1, align: "start" }}
					products={displayProducts ?? []}
				/>
			)}
			{activeTab && activeTab.link && (
				<Link href={activeTab.link} className={styles.viewAll}>
					<Text className={styles.viewAllText}>
						{t("viewAll", {
							category: activeTab.title,
						})}
					</Text>
					<Arrow24 />
				</Link>
			)}
		</div>
	);
};
