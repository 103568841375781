"use client";

import { Select } from "@/components/ui/Select";
import { Text } from "@/components/ui/Text";
import { LocationInputs, useLocationAction } from "@frend-digital/centra/location";
import type { CountryAutoResponse } from "@frend-digital/centra/next";
import { useLocale, useTranslations } from "next-intl";
import { startTransition, useCallback, useRef } from "react";
import { LOCALES } from "../../../i18n/config";
import styles from "./index.module.css";

export const LanguageSelect = ({
	userdata,
}: {
	userdata: CountryAutoResponse | null;
}) => {
	const locale = useLocale();
	const t = useTranslations("i18n.language");

	const contentRef = useRef<HTMLDivElement>(null!);

	const [_, formAction] = useLocationAction();

	const trigger = useCallback(
		(newLanguage: string) => {
			startTransition(() => {
				const formData = new FormData();

				formData.append(LocationInputs.country, userdata?.country?.country ?? "NO");
				formData.append(LocationInputs.language, newLanguage);

				formAction(formData);
			});
		},
		[userdata?.country?.country, formAction],
	);

	return (
		<div className={styles.content} ref={contentRef}>
			<Text variant="h4" bold>
				{t("header")}
			</Text>
			<Text variant="body2">{t("subHeader")}</Text>
			<Select
				onValueChange={(value) => {
					if (value === locale) return;
					trigger(value);
				}}
				large
				hideReset
				placeholder={t("placeholder")}
				name={LocationInputs.language}
				defaultValue={locale}
				items={LOCALES.map((l) => ({
					name: t(l),
					value: l,
				}))}
			/>
		</div>
	);
};
